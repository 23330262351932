import xhr from './xhr/';
/**
 * home页相关的 API
 */
class HomeService {
	// 增加门店复健
	addReConstru(data) {
		return xhr({
			method: 'post',
			url: '/fujian/add',
			params: data,
		});
	}
	// 查询门店复健
	queryReConstru(data) {
		return xhr({
			method: 'get',
			url: '/fujian/list',
			params: data,
		});
	}
	// 上传图片
	upLoadPhoto(data) {
		return xhr({
			method: 'post',
			url: '/fujian/photo/upload',
			params: data,
		});
	}
	// 删除记录
	deleteRecontruc(data) {
		return xhr({
			method: 'post',
			url: '/fujian/delete',
			params: data,
		});
	}
	// 复健详情
	recontrucDetail(data) {
		return xhr({
			method: 'post',
			url: '/fujian/xq',
			params: data,
		});
	}
}
export default new HomeService();
